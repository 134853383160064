<template>
  <div class="main" :style="{'background-color':bgc}">
    <div class="SpellGroupBanner">
      <img :src="bannerBgc" alt="">
    </div>
    <div class="SpellGroupRules">
      <p class="SpellGroupRulesText">拼团限时48小时，未完成拼团或抽中免单后，支付金额将第一时间返回悦淘钱包余额;</p>
      <p class="SpellGroupRulesText">拼团订单请在我的订单拼团订单中查看，拼团成功后将自动发货，请及时查看;</p>
    </div>
    <div class="SpellGroupTitle" />
    <div class="SpellGroupGoodsList">
      <ul class="SpellGroupGoodsListWrap">
        <li
          v-for="(item,index) in SpellGroupGoodsList"
          :key="index"
          class="SpellGroupGoodsListDetail"
          @click="goOrderDetail(item.goodsId,item.id)"
        >
          <div class="GoodsUrlWrap">
            <img :src="item.goodsImg" alt="" class="GoodsUrl">
          </div>
          <div class="GoodsText">
            <div class="GoodsNameWrap">
              <p class="GoodsName">{{ item.goodsName }}</p>
              <!--                            <p class="GoodsName">{{item.number1}}*{{item.number2}}</p>-->
            </div>
            <div class="GoodsTextPrice">
              <div class="GoodsTextPriceWrap">
                <p class="GoodsTextPrice1">拼团价¥ {{ item.teamPrice }}</p>
                <p class="GoodsTextPrice2">原价¥ {{ item.originPrice }}</p>
              </div>
              <div class="GoodsTextBtnWrap">发起拼团</div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getGoodsList } from '@/services/goods.js'

export default {
  name: 'Index',
  data() {
    return {
      bgc: '#F32936',
      bannerBgc: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-10-14/15/yuelvhuilZxyTBMQjx1602660283.png',
      SpellGroupGoodsList: []
    }
  },
  created() {

  },
  mounted() {
    this.getGoodsList()
  },
  methods: {
    // get商品列表
    getGoodsList() {
      getGoodsList({
        'channel': 7,
        'page': 1,
        'pageSize': 100000
      }).then(res => {
        if (Number(res.code) === 200) {
          this.bgc = res.data.ad.background_color// 背景颜色
          this.bannerBgc = res.data.ad.background_image// banner图
          this.SpellGroupGoodsList = res.data.item// 商品列表
        }
      })
    },
    // 跳转商品详情
    goOrderDetail(goodsId, id) {
      if (window.localStorage.getItem('uid')) {
        this.$router.push({ path: '/goodsListDetail', query: { goodsId: goodsId, team_id: id }})
      } else {
        this.$store.dispatch('loginIntercept', this.$route.query)
      }
    }
  }
}
</script>

<style scoped>
html, body {
  width: 100%;
  height: 100%;
}

.main {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: #F32936;
}

/*规则*/
.SpellGroupRules {
  padding: 7px 19px 11px 14px;
  text-align: left;
}

.SpellGroupRulesText {
  color: #fff;
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
}

.SpellGroupTitle {
  height: 20px;
  margin: 0 auto;
  background: url("https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-10-14/15/yuelvhuiL9XVT4GxXH1602660332.png") left center no-repeat;
  background-size: 100%;
}

.SpellGroupGoodsList {
  padding: 15px 14.5px 56px 14.5px;
}

.SpellGroupGoodsListWrap {
  width: 100%;
}

.SpellGroupGoodsListDetail {
  display: flex;
  flex: 4;
  border-radius: 7px;
  background-color: #fff;
  /*height:109px;*/
  padding: 10px;
  margin-bottom: 7px;
}

.GoodsUrlWrap {
  width: 89px;
  height: 89px;
  border-radius: 4px;
  flex: 1.177;
}

.GoodsUrl {
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.GoodsText {
  flex: 3;
  margin-left: 10px;
  margin-top: 6px;
  text-align: left;
}

.GoodsNameWrap {
  margin-bottom: 22px;
}

.GoodsName {
  color: #333;
  height: 20px;
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  /*white-space:nowrap;*/
}

.GoodsTextPrice {
  display: flex;
}

.GoodsTextPrice1 {
  color: #F7263C;
  font-size: 12px;
  margin-bottom: 5px;
}

.GoodsTextPrice2 {
  color: #999;
  font-size: 12px;
  text-decoration: line-through;
}

.GoodsTextBtnWrap {
  margin-left: auto;
  width: 73px;
  height: 27px;
  background: #f7263c;
  border-radius: 3px;
  text-align: center;
  line-height: 27px;
  color: #FEFEFE;
  font-size: 12px;
}
</style>
